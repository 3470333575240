import { Link } from 'react-router-dom';
function App(props) {
    
  return (<div className="container-fluid">
    <div className="row px-xl-5">
        <div className="col-12">
            <nav className="breadcrumb bg-light mb-30">
                <Link className="breadcrumb-item text-dark2" to="/">Inicio</Link>
                <Link className="breadcrumb-item text-dark2" to={props.url}>{props.categoria}</Link>
                <span className="breadcrumb-item active">{props.descripcion}</span>
            </nav>
        </div>
    </div>
</div>);
}

export default App;
