import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import {Cart4} from 'react-bootstrap-icons';
import { actualizarNumeroce } from './carrito.js';

function CarroNum() {
/*     const [numCarrito, setnumCarrito] = useState(0);
    const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("toytek-carrito")) || []);


const contar = () =>{
    const productos = JSON.parse(localStorage.getItem("toytek-carrito"));
    setProductosEnCarrito(productos)
    const numeroc = productosEnCarrito.reduce((acc, producto) => acc + producto.cantidad, 0);
    setnumCarrito(Number(numeroc));
}

    useEffect(() => {
        contar();
            }, productosEnCarrito);
 */
    return (<Link to="/cart" className="nav-item nav-link">
        <Cart4 size={24} color='white'></Cart4>&nbsp;
        <span id="numerito" className="badge text-secondary border border-secondary" style={{ paddingBottom: '2px' }}>{actualizarNumeroce()}</span>
    </Link>);
}


export default CarroNum;

