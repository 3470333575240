 
import {useRef, useEffect, useState } from 'react';
import '../css/style.css';
import Swal from 'sweetalert2'
import {Whatsapp, CreditCard, CashStack} from 'react-bootstrap-icons';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';
function Checkout() {
    const nf = new Intl.NumberFormat("es-CL");
/*     const { register, formState: { errors }, handleSubmit} = useForm(); */
    const [productosEnCarritoCk, setProductosEnCarritoCk] = useState(JSON.parse(localStorage.getItem("toytek-carrito")) || []);
    const form2 = useRef();
    const [cadenaProductos, setcadenaProductos] = useState(null);
    const [formData, setFormData] = useState(JSON.parse(localStorage.getItem("toytek-formulario")) || {
        nombre: "",
        rut: '',
        email: '',
        phone: '',
        direccion1: '',
        direccion2: '',
        pais: '',
        ciudad: '',
        otros: '',
        postal: ''
      });
    
    const [botonHabilita, setbotonHabilita] = useState(true);
    let TvalorProductoArt = 0, TvalorProductoArtFormat = 0;

    const clickingPago2 = () => {
        
        Swal.fire({
            title: `¿Pagar $ ${nf.format(TvalorProductoArt)}.-?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "PAGAR"
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('toytek-carrito');
                setbotonHabilita(true);
                const url = 'https://link.mercadopago.cl/toytek';
                var win = window.open(url, '_blank');
                win.focus();
                /* window.location.reload(true);  */
                const urlParent = 'https://toytek.cl/compra-pendiente';
                var win2 = window.open(urlParent, '_parent');
                win2.focus();
            }
            else{
                
                Swal.fire({
                    title: "Eliminar el Carrito",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Eliminar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('toytek-carrito');
                        window.location.reload(true); 
                    }
                    
                })
                
            }
        });
    }

    const sendEmail = () => {
        /*   e.preventDefault(); */
        Swal.fire({
            title: "¿Confirmación de compra?",
            icon: "info",
            html: "Recibir el detalle de su pedido, para posteriormente pagar.",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmo!"
        }).then((result) => {
            if (result.isConfirmed) {
                emailjs
            .sendForm(process.env.REACT_APP_FORMSERVICE, process.env.REACT_APP_FORMPRODUCTOSTEMPLATE, form2.current, {
                publicKey: process.env.REACT_APP_FORMPUBLICKEY,
            })
            .then(
                () => {
                    console.log('ok');
                    let email = '<b>'+document.getElementById('email').value+'</b>' || '';
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Enviado",
                        /* text: "Su Mensaje se envió", */
                        html: `Se envió un email a: <b>${email}</b>,  TRANSFERENCIAS hábleme al WHATSAPP`,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6"/* ,
            timer: 3000 */
                    }).then((result) => {
                        if (result.isConfirmed) {
                            clickingPago2();
                        }
                    });

                },
                (error) => {
                    console.log('error:', error.text);
                    Swal.fire({
                        title: 'Error!',
                        text: error.text,
                        icon: 'error',
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: 'OK'
                    })
                }
            );
            }
            
        })

        
    }


    const cargarProductos = () => {
        const productos = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
        
        if(productos !== null)
        {
            setProductosEnCarritoCk(productos);
            const cadenaProductosString = productosEnCarritoCk.map(producto => {
            return `ID: ${producto.id}, Descripción: ${producto.descripcion}, Monto: $ ${nf.format(producto.valor)}.-`;
            }).join('\n');
            setcadenaProductos (cadenaProductosString);
        }
    };

    const revisaForm = () => {
       if(document.getElementById('nombre').value !== '' 
       && document.getElementById('rut').value !== '' 
       && document.getElementById('email').value !== '' 
       && document.getElementById('direccion1').value !== ''
       && document.getElementById('ciudad').value !== ''
       && document.getElementById('pais').value !== ''){
        setbotonHabilita(false);

    }
    else{
        setbotonHabilita(true);
    }
}; 

const revisaCheckcout = () => {
    if(localStorage.getItem('toytek-carrito') === null)
    {
        const url = '../';
        var win = window.open(url, '_parent');
        win.focus();
        return false;
    }
    else{
        return true;
    }
}
useEffect(() => {
        if(revisaCheckcout()){
        cargarProductos();
        revisaForm();
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('toytek-formulario', JSON.stringify(formData));
            }, [formData]);
        
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

        // Función que se ejecuta al enviar el formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    // Guardar los datos del formulario en el localStorage
    localStorage.setItem('toytek-formulario', JSON.stringify(formData));
    console.log('Datos guardados en localStorage:', formData);

    sendEmail();
            /* document.getElementById("form").submit();
            form2.current.reset(); */
  };

    return (<div className="container-fluid" style={{paddingTop: '140px'}}>
        <div className="row px-xl-5">
            <div className="col-lg-8">
                <h5 className="section-title position-relative text-uppercase mb-3"><span className="pr-3" style={{color:'white'}}>Datos del Despacho</span></h5>
                <div className="bg-light p-30 mb-5">
                <form id="form" ref={form2} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Nombre</label>
                            <input className="form-control" type="text" id="nombre" name="nombre" placeholder="Trap Jaw" onKeyUp={revisaForm} onChange={handleChange} value={formData.nombre || ''} required/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>RUT</label>
                            <input className="form-control" type="text" id="rut" name="rut" placeholder="11222333-K" onKeyUp={revisaForm} onChange={handleChange} value={formData.rut} required/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>E-mail</label>
                            <input className="form-control" type="text" id="email" name="email" placeholder="trap.jaw@email.com" onKeyUp={revisaForm} onChange={handleChange} autoComplete='email' value={formData.email} />
            {/*                 {
                                errors.email?.type === "pattern" && (
                                    <p style={{ color: 'red' }}>¡Formato del Email no válido!</p>
                                )
                            }
                            {
                                errors.email?.type === "required" && (
                                    <p style={{ color: 'red' }}>¡Email es requerido!</p>
                                )
                            }
                            {
                                errors.email?.type === "minLength" && (
                                    <p style={{ color: 'red' }}>¡Email son mínimo 5 caracteres!</p>
                                )
                            }
                            {
                                errors.email?.type === "maxLength" && (
                                    <p style={{ color: 'red' }}>¡Email largo máximo de 40 caracteres!</p>
                                )
                            } */}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Celular</label>
                            <input className="form-control" type="tel" id="phone" name="phone" placeholder="+56922221111"  onKeyUp={revisaForm} onChange={handleChange} value={formData.phone|| ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Dirección Linea 1</label>
                            <input className="form-control" type="text" id="direccion1" name="direccion1" placeholder="Avenida Siempreviva 742" onChange={handleChange} onKeyUp={revisaForm} value={formData.direccion1|| ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Dirección Linea 2</label>
                            <input className="form-control" type="text" id="direccion2" name="direccion2" placeholder="Nevada"  onKeyUp={revisaForm} onChange={handleChange} value={formData.direccion2|| ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>País</label>
                            <select defaultValue="false" id="pais" name="pais" className="custom-select" onKeyUp={revisaForm} onChange={handleChange} select={formData.pais} required>
                                <option value="Chile">Chile</option>
                                <option value="Colombia">Colombia</option>
                                <option value="España">España</option>
                                <option value="Estados unidos">Estados unidos</option>
                                <option value="República Dominicana">República Dominicana</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Ciudad</label>
                            <input className="form-control" type="text" id="ciudad" name="ciudad" placeholder="Santiago"  onKeyUp={revisaForm} onChange={handleChange} value={formData.ciudad|| ''} required/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>OTROS DATOS</label>
                            <input className="form-control" type="text" id="otros" name="otros" placeholder=""  onKeyUp={revisaForm} onChange={handleChange} value={formData.otros|| ''}/>
                            <input className="form-control" type="hidden" id="productos" name="productos" placeholder=""  onKeyUp={revisaForm} onChange={handleChange} value={cadenaProductos  || ''}/>
                            <input className="form-control" type="hidden" id="total" name="total" placeholder=""  onKeyUp={revisaForm} onChange={handleChange} value={`$ ${nf.format(productosEnCarritoCk.reduce((acc, producto) => acc + producto.valor, 0))}` || ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Código Postal</label>
                            <input className="form-control" type="text" id="postal" name="postal" placeholder="2345678"  onKeyUp={revisaForm} onChange={handleChange} value={formData.postal || ''}/>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <div className="col-lg-4">
                <h5 className="section-title position-relative text-uppercase mb-3"><span className="pr-3" style={{color:'white'}}>Total</span></h5>
                <div className="bg-light p-30 mb-5">
                    <div className="border-bottom">
                        <h6 className="mb-3">Productos</h6>
                        {   productosEnCarritoCk.length > 0 &&
                productosEnCarritoCk.sort((a, b) => a.valor - b.valor).map((producto, i) => {
                var valorProductoArt = `$ ${nf.format(producto.valor)}`;
                TvalorProductoArt = productosEnCarritoCk.reduce((acc, producto) => acc + producto.valor, 0);
                TvalorProductoArtFormat = `$ ${nf.format(TvalorProductoArt)}`;
            
                return (<div key={`${producto.id}`} className="d-flex justify-content-between" >
                            <p style={{fontSize:'14px'}}>{producto.descripcion.toString().substring(0, 28)+'...'}</p>
                            <p style={{fontSize:'16px'}}>{valorProductoArt}</p>
                        </div>)
        })
      }  
                    </div>
                    <div className="border-bottom pt-3 pb-2">
                        <div className="d-flex justify-content-between mb-3">
                            <h6>Sub Total</h6>
                            <h6>{TvalorProductoArtFormat}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                        <h6 className="font-weight-medium">Despacho <span style={{color:'blue',fontWeight: 'lighter'}}>STARKEN</span><span style={{color:'blue',fontWeight: 'bold'}}> VALPARAÍSO</span></h6>
                            <h6 className="font-weight-medium">por Pagar</h6>
                        </div>
                    </div>
                    <div className="pt-2">
                        <div className="d-flex justify-content-between mt-2">
                            <h5>Total</h5>
                            <h5>{TvalorProductoArtFormat}</h5>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3" style={{color:'white'}}>Pago</span></h5>
                    <div className="bg-light p-30">
                        <div className="form-group">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="payment" id="mercadopago" checked={true} readOnly/>
                                <label className="custom-control-label"><b>Visa / Mastercard / Débito</b> <CreditCard color='orange' size={24}></CreditCard> <CashStack color='green' size={24}></CashStack></label>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="payment" id="banktransfer" disabled/>
                                <label className="custom-control-label">Transferencia Bancaria (<b>Hábleme al Whatsapp</b>) <Whatsapp color='green' size={24}></Whatsapp></label>
                            </div>
                        </div>
                        <button className="btn btn-block btn-primary font-weight-bold py-3" onClick={sendEmail} type='submit' disabled={botonHabilita}>Realizar Pedido</button>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Checkout;