
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams } from 'react-router-dom';
import data from "../helps/output.json";
import Breadcrumb from '../components/breadcrumb.js';
import '../css/style.css';
import '../App.css';
import{ArrowLeftShort, ArrowRightShort, Cart4} from 'react-bootstrap-icons';
import { /* actualizarBotonesAgregar, agregarAlCarrito,  */agregarAlCarritoDetalle } from './carrito.js';

function App() {
    const [itemValor, setValor] = useState('VENDIDO');
    const [item, setItem] = useState([]);
    const [itemfoto1, setitemfoto1] = useState('');
    const [itemfoto2, setitemfoto2] = useState('');
    const [itemfoto3, setitemfoto3] = useState('');
    const [itemfoto4, setitemfoto4] = useState('');
    const [itemfoto5, setitemfoto5] = useState('');
    const [itemfoto6, setitemfoto6] = useState('');
    const [itemfoto7, setitemfoto7] = useState('');
    const [itemfoto8, setitemfoto8] = useState('');
    const url = useParams().url;

    const revisaF = (item)=>{
        revisaFotos1(item);
        revisaFotos2(item);
        revisaFotos3(item);
        revisaFotos4(item);
        revisaFotos5(item);
        revisaFotos6(item);
        revisaFotos7(item);
        revisaFotos8(item);
    }
    const cambio = (url) => {
        return new Promise((resolve, reject) => {
            const item = data.find((el) => el.url === url);
            const nf = new Intl.NumberFormat("es-CL");
            if (item) {
                if (item.cantidad > 0)
                    resolve(`$ ${nf.format(item.valor)}.-`);
                else
                    resolve(`VENDIDO`);

                   
            }
        })
    }

    const revisaFotos1 = (itemF) =>{
        let fotoFin = '';
        if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto1 !== '')
                fotoFin = `${itemF.foto1}`;
        }
        else{
            fotoFin = `${itemF.foto1}.JPG`;
        }
        setitemfoto1(fotoFin);
    }

    const revisaFotos2 = (itemF) =>{
        let fotoFin = '';
        if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto2 !== '')
                fotoFin = `${itemF.foto2}`;
        }
        else{
            fotoFin = `${itemF.foto2}.JPG`;
        }
        setitemfoto2(fotoFin);
    }

    const revisaFotos3 = (itemF) =>{
        let fotoFin = '';
        if(itemF.categorias === Number(15)){
            if(itemF.foto3 !== '')
                fotoFin = `${itemF.foto3}`;
        }
        else  if(itemF.categorias === Number(16)){
      /*       if(itemF.foto3 !== '')
                fotoFin = ``; */
        }
        else{
            fotoFin = `${itemF.foto3}.JPG`;
        }
        setitemfoto3(fotoFin);
    }

    const revisaFotos4 = (itemF) =>{
         let fotoFin = '';
        /*if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto4 !== '')
                fotoFin = `${itemF.foto4}`;
        }
        else{ */
            fotoFin = `${itemF.foto4}.JPG`;
        /* } */
        setitemfoto4(fotoFin);
    }
    
    const revisaFotos5 = (itemF) =>{
        let fotoFin = '';
        /* if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto5 !== '')
                fotoFin = `${itemF.foto5}`;
        }
        else{ */
            fotoFin = `${itemF.foto5}.JPG`;
        /* } */
        setitemfoto5(fotoFin);
    }

    const revisaFotos6 = (itemF) =>{
        let fotoFin = '';
        /* if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto6 !== '')
                fotoFin = `${itemF.foto6}`;
        }
        else{ */
            fotoFin = `${itemF.foto6}.JPG`;
        /* } */
        setitemfoto6(fotoFin);
    }

    const revisaFotos7 = (itemF) =>{
        let fotoFin = '';
        /* if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto7 !== '')
                fotoFin = `${itemF.foto7}`;
        }
        else{ */
            fotoFin = `${itemF.foto7}.JPG`;
        /* } */
        setitemfoto7(fotoFin);
    }

    const revisaFotos8 = (itemF) =>{
        let fotoFin = '';
        /* if(itemF.categorias === Number(15) || itemF.categorias === Number(16)){
            if(itemF.foto8 !== '')
                fotoFin = `${itemF.foto8}`;
        }
        else{ */
            fotoFin = `${itemF.foto8}.JPG`;
        /* } */
        setitemfoto8(fotoFin);
    }
    
    const pedirDatoporURL = (url) => {
        return new Promise((resolve, reject) => {
            const item = data.find((el) => el.url === url);
            if (item) {
                resolve(item);
            } else {
                /* reject({ error: "No se encontro el producto" }); */
                const url = 'https://www.toytek.cl';
                var win = window.open(url, '_parent');
                win.focus();

            }


        })
    }

    useEffect(() => {
        pedirDatoporURL(url)
            .then((res) => {
                setItem(res);
            
            })
    }, [url])

    useEffect(() => {
        cambio(url).then((res) => {
            setValor(res);
            
        }).catch((err) => {
            console.error("Error al obtener el valor del ítem:", err);
        });

    }, [url]);


    useEffect(() => {
        pedirDatoporURL(url)
            .then((res) => {
                revisaF(res);
                
            })
    }, )

    const textAlt = item.descripcion;
    const newTextAlt = textAlt;
/*     var foto1,foto2,foto3; */

/*     if(item.categorias === 15){
        foto1 = `${item.foto1}`;
        foto2 = `${item.foto2}`;
        foto3 = `${item.foto3}`;
    }else if(item.categorias === 16){
        foto1 = `${item.foto1}`;
        foto2 = `${item.foto2}`;
        foto3 = null;
    }
    else{
        foto1 = `${item.foto1}.JPG`;
        foto2 = `${item.foto2}.JPG`;
        foto3 = null;
    } */
    /*       const RevisaImagen = (props) => {
            if(props.src !== null && props.src !== ''){
                return  (<div className="carousel-item"><LazyLoadImage className="w-100 h-100" src={`${props.src}.JPG`} alt={props.alt} /></div>)
            }
           
        }
     */
/*         const timestamp = new Date().getTime(); */
/*         const imageUrl = `/ruta-de-la-imagen.jpg?v=${timestamp}`; */

        
    return (<div style={{paddingTop: '180px'}}><Breadcrumb categoria={item.cat_nombre} descripcion={item.descripcion} url={item.cat_url}></Breadcrumb>
    <div className="container-fluid pb-5">
        <div className="row px-xl-5">
            <div className="col-lg-5 mb-30">
                <div id="product-carousel" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner bg-light">
                        <div className="carousel-item active">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto1} alt={`${newTextAlt}-1`} />
                        </div>
                        <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto2} alt={`${newTextAlt}-2`} />
                        </div>
                        {(itemfoto3 !== null && (itemfoto3 !== '.JPG' && itemfoto3 !== '')) &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto3} alt={`${newTextAlt}-3`} />
                        </div>}
                        {(itemfoto4 !== null && itemfoto4 !== '.JPG') &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto4} alt={`${newTextAlt}-4`} />
                        </div>}
                        {(itemfoto5 !== null && itemfoto5 !== '.JPG') &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto5} alt={`${newTextAlt}-5`} />
                        </div>}
                        {(itemfoto6 !== null && itemfoto6 !== '.JPG') &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto6} alt={`${newTextAlt}-6`} />
                        </div>}
                        {(itemfoto7 !== null && itemfoto7 !== '.JPG') &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto7} alt={`${newTextAlt}-7`} />
                        </div>}
                        {(itemfoto8 !== null && itemfoto8 !== '.JPG') &&  <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={itemfoto8} alt={`${newTextAlt}-8`} />
                        </div>}                    
                    </div>
                    <Link className="carousel-control-prev" data-target="#product-carousel" data-slide="prev">
                    <ArrowLeftShort size={48} color='white'></ArrowLeftShort>
                    </Link>
                    <Link className="carousel-control-next" data-target="#product-carousel" data-slide="next">
                    <ArrowRightShort size={48} color='white'></ArrowRightShort>
                    </Link>
                </div>
            </div>

            <div className="col-lg-7 h-auto mb-30">
                <div className="h-100 bg-light p-30">
                    <h3>{item.descripcion}</h3>
                    <div className="d-flex mb-3">
                        <div className="text-primary mr-2">

                        </div>

                    </div>
       {/*              <h3 className="font-weight-semi-bold mb-4">{item.cat_nombre}</h3> */}
                    <p className="mb-4">{item.descripcion}</p>
                 {/*    <div className="d-flex mb-3">

                    </div> */}
                    <div className="d-flex mb-4">
                       <h3>{itemValor}</h3> 
                    </div>
                    <div className="d-flex align-items-center mb-4 pt-2" >
                        <div className="input-group quantity mr-3" style={{ width: '130px' }}>
                            <div className="input-group-btn">
                                <button className="btn btn-primary btn-minus" disabled>
                                <ArrowLeftShort size={20} color='white'></ArrowLeftShort>
                                </button>
                            </div>
                            <label className="form-control bg-secondary border-0 text-center">{item.cantidad}</label>
                            <div className="input-group-btn">
                                <button className="btn btn-primary btn-plus" disabled>
                                <ArrowRightShort size={20} color='white'></ArrowRightShort>
                                </button>
                            </div>
                        </div>
                        <button className="btn btn-primary px-3 producto-agregar" style={{ visibility: itemValor === 'VENDIDO' ? 'hidden' : 'visible', color: 'white, !important' }} id={`${item.id}`} onClick={agregarAlCarritoDetalle}><Cart4 size={24} color='white'></Cart4></button>

                    </div>
                </div>
            </div>
        </div>
        <div className="row px-xl-5">
            <div className="col">
                <div className="bg-light p-30">
                    <div className="nav nav-tabs mb-4">
                        <Link className="nav-item nav-link text-dark2 active" data-toggle="tab" to="">Descripción</Link>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="tab-pane-1">
                            <h4 className="mb-3">Detalle</h4>
                            <p>{item.descripcion}</p>
                            <p><li>{item.estado}.</li></p>
                            <p><li>Todos los artículos son recomendados <b>para mayores de 3 años</b>.</li></p>
                            <p><li>Los despachos se realizan desde: <b>{item.sucursal} - Chile</b>.</li></p>
                            <p><li>Los despachos son por <b>{item.empresa_envio} {item.envio}</b>.</li></p>
                            <p><li>Si tiene dudas consulte.</li></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></div>)
}

export default App;
