/* import React, { useState } from 'react'; */
import '../App.css';
/* import '../css/style.css'; */
/* import * as Icon from 'react-bootstrap-icons'; */
import Video from '../components/video.js';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
import Wsp from '../components/wsp.js';
import Politicas from '../components/politicas.js';
import Categorias from '../components/categorias.js';
/* 
import Carousel from '../components/carousel.js';
import Item from '../components/items_new.js'; */
/* import '../assets/mp3/applite.mp3'
import applite from '../assets/mp3/applite.mp3' */

function App() {

return (<>


  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
<div style={{zIndex: '998', position: 'relative', paddingTop:'180px' }}><Politicas/><Categorias /></div>
    <Wsp></Wsp></>
);
}

export default App;