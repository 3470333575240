import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PinMap, Envelope, PhoneVibrate } from 'react-bootstrap-icons';
import React, { useRef, useEffect, useState, useCallback } from 'react';
/* import ReCAPTCHA from 'react-google-recaptcha'; */
/* import * as Icon from 'react-bootstrap-icons'; */
import emailjs from '@emailjs/browser';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
/* import '../css/form_contacto.css'; */
/* import { Link } from "react-router-dom"; */
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
/* import dotenv from "dotenv"; */


function FormContacto() {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [captchaIsDone, SetCaptchaDone] = useState(true);
    const publica = process.env.REACT_APP_KEYPUBLIC;

/*     dotenv.config({path:'.env'}); */
    const form2 = useRef();
    /* function onChange(){ */
    /*   recaptchaRef.current.execute(); */
    /*   SetCaptchaDone(true);
    } */
    const sendEmail = () => {
        /*   e.preventDefault(); */

        emailjs
            .sendForm(process.env.REACT_APP_FORMSERVICE, process.env.REACT_APP_FORMTEMPLATE, form2.current, {
                publicKey: process.env.REACT_APP_FORMPUBLICKEY,
            })
            .then(
                () => {
                    console.log('ok');

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Enviado",
                        text: "Su Mensaje se envió",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6"/* ,
            timer: 3000 */
                    }).then((result) => {
                        if (result.isConfirmed) {
                            form2.current.reset();
                         /*    player(); */
                        }
                    });

                },
                (error) => {
                    console.log('error:', error.text);
                    Swal.fire({
                        title: 'Error!',
                        text: error.text,
                        icon: 'error',
                        confirmButtonText: 'Cool'
                    })
                }
            );
    }

    const eliminaTildes = (e) => {
        e.preventDefault();
        var titulo = e.target.value.replace("á", 'a').replace("é", 'e').replace("í", 'i').replace("ó", 'o').replace("ú", 'u').replace("ü", 'u').replace("Á", 'A').replace("É", 'E').replace("Í", 'I').replace("Ó", 'O').replace("Ú", 'U').replace("Ü", 'U');
        e.target.value = titulo;
    }


    useEffect(() => {
        if (window.grecaptcha) {
            SetCaptchaDone(true);
        } else {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
            script.async = true;
            script.id = 'rc';
            document.head.appendChild(script);
            script.onload = () => { SetCaptchaDone(true) }
        }
    }, [publica])

    const executeRecaptcha = useCallback(async (action) => {
        if (captchaIsDone && window.grecaptcha) {
            return await window.grecaptcha.execute(publica, { action })
        } else {
            return executeRecaptcha;
        }
    })


    return (<div className="container-fluid">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Contactenos</span></h2>
        <div className="row px-xl-5">
            <div className="col-lg-7 mb-5">
                <div className="contact-form bg-light p-30">
                    <form id="form" ref={form2} onSubmit={handleSubmit(() => { sendEmail(); })}>
                        <div className="form-group">
                            <label className="sr-only" style={{ color: 'white' }} htmlFor="user_name">Nombre</label>
                            <input type="text" {...register("user_name", { required: true, minLength: 3, maxLength: 40 })} onChange={eliminaTildes}
                                id="user_name" placeholder="Nombre..." className="contact-nombre form-control" autoComplete='name' name="user_name" />
                            {
                                errors.user_name?.type === "required" && (
                                    <p style={{ color: 'red' }}>¡Su Nombre es requerido!</p>
                                )
                            }
                            {
                                errors.user_name?.type === "minLength" && (
                                    <p style={{ color: 'red' }}>Nombre son mínimo 3 caracteres!</p>
                                )
                            }
                            {
                                errors.user_name?.type === "maxLength" && (
                                    <p style={{ color: 'red' }}>Nombre largo máximo de 40 caracteres!</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className="sr-only" style={{ color: 'white' }} htmlFor="user_email">Email</label>
                            <input type="text" {...register("user_email", { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i, required: true, minLength: 5, maxLength: 40 })}
                                id="user_email" placeholder="Email..." className="contact-email form-control" autoComplete='email' />
                            {
                                errors.user_email?.type === "pattern" && (
                                    <p style={{ color: 'red' }}>¡Formato del Email no válido!</p>
                                )
                            }
                            {
                                errors.user_email?.type === "required" && (
                                    <p style={{ color: 'red' }}>¡Email es requerido!</p>
                                )
                            }
                            {
                                errors.user_email?.type === "minLength" && (
                                    <p style={{ color: 'red' }}>¡Email son mínimo 5 caracteres!</p>
                                )
                            }
                            {
                                errors.user_email?.type === "maxLength" && (
                                    <p style={{ color: 'red' }}>¡Email largo máximo de 40 caracteres!</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className="sr-only" style={{ color: 'white' }} htmlFor="message">Mensaje</label>
                            <textarea  {...register("message", { required: true, minLength: 3, maxLength: 200, })}
                                id="message" placeholder="Mensaje..." className="contact-message form-control" ></textarea>
                            {
                                errors.message?.type === "required" && (
                                    <p style={{ color: 'red' }}>¡Un Mensaje es requerido!</p>
                                )
                            }
                            {
                                errors.message?.type === "minLength" && (
                                    <p style={{ color: 'red' }}>¡Mensaje son mínimo 3 caracteres!</p>
                                )
                            }
                            {
                                errors.message?.type === "maxLength" && (
                                    <p style={{ color: 'red' }}>¡Mensaje largo máximo de 200 caracteres!</p>
                                )
                            }
                        </div>
                        <div className="form-bottom">
                    {captchaIsDone && <button type="submit" className="btn btn-primary py-2 px-4">Enviar Mensaje</button>}
                </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-5 mb-5">
                <div className="bg-light p-30 mb-30" style={{ textAlign: 'center' }}>
                    <LazyLoadImage style={{ border: '0', height: '185px' }} className="img-fluid" src={`../assets/carousel/carousel-1.jpg`} alt="" key="carr-1" />
                </div>
                <div className="bg-light p-30 mb-3">
                    <p className="mb-2"><PinMap size={24} color='blue'></PinMap> Valparaíso - Chile</p>
                    <p className="mb-2"><Envelope size={24} color='blue'></Envelope> hola@toytek.cl</p>
                    <p className="mb-2"><PhoneVibrate size={24} color='blue'></PhoneVibrate> +56 9 9930 2909</p>
                </div>
            </div>
        </div>
    </div>);
}

export default FormContacto;
