/* import React, { useState } from 'react'; */
/* import '../App.css'; */
/* import '../css/style.css'; */
/* import * as Icon from 'react-bootstrap-icons'; */
import Video from '../components/video.js';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
import Wsp from '../components/wsp.js';

import Contacto from '../components/contacto.js';
/* import Carousel from '../components/carousel.js';
import Item from '../components/items_new.js'; */
import Breadcrumb from '../components/breadcrumb.js';
/* import '../assets/mp3/applite.mp3'
import applite from '../assets/mp3/applite.mp3' */
function App() {

return (
  <>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><div><Breadcrumb categoria={`Contacto`} descripcion={``} url={``}></Breadcrumb></div>
   <div style={{paddingTop: '160px'}}><Contacto/></div>
    <Wsp></Wsp></>
);
}

export default App;