/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
function App() {

    const imagesGalery = [
        'galeria-01.jpg', 'galeria-02.jpg', 'galeria-03.jpg', 'galeria-04.jpg',
        'galeria-05.jpg', 'galeria-06.jpg', 'galeria-07.jpg', 'galeria-08.jpg',
        'galeria-09.jpg', 'galeria-10.jpg', 'galeria-11.jpg', 'galeria-12.jpg',
        'galeria-13.jpg','galeria-14.jpg','galeria-15.jpg','galeria-16.jpg',
        'galeria-17.jpg','galeria-18.jpg','galeria-19.jpg'
        ,'galeria-20.jpg','galeria-21.jpg','galeria-22.jpg','galeria-23.jpg'
      ];
      
      return (
        <div className="container-fluid" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="gallary row">
            {imagesGalery.sort(() => 0.5 - Math.random()).slice(0, 8).map((image, index) => (
              <div key={index} className="col-sm-6 col-lg-33 gallary-item wow fadeIn">
                <Link to={ image !== 'galeria-23.jpg' ? "/collections/poleras" : "/collections/tazas"}>
                  <LazyLoadImage
                    src={`../assets/galeria/${image}`}
                    alt={`polera-galeria-${index + 1}`}
                    className="gallary-img"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
}

export default App;
