import { useEffect, useState } from 'react';
import '../css/style.css';
import '../App.css';
/* import dotenv from "dotenv"; */
/* import data from "../helps/output.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
 */
import { Link } from 'react-router-dom';
/* import Swal from 'sweetalert2' */
import './carrito.js';
import {eliminarDelCarrito} from './carrito.js';
import {XLg} from 'react-bootstrap-icons';
/* import * as Icon from 'react-bootstrap-icons'; */

//1
/* import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import axios from "axios"; */



function App() {
    //2
    const nf = new Intl.NumberFormat("es-CL");
/*     const [preferenceId, setpreferenceId] = useState(null);
    initMercadoPago("APP_USR-37075d63-5c3f-46b6-ad69-2150dcc9b39e",{//test
        locale: "es-CL",
    }); */

/*     const createPreference = async ()=>{
        try {
            console.log('Enviando solicitud a la API...');

            const response = await axios.post("http://localhost:1555/create_preference", {
                title: "Producto Pack",
                quantity: 1,
                price: 1000,
              }, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'APP_USR-b5d986bc-e509-45f8-99cf-66b785b1d356', // Reemplaza con tu token válido
                },
               // withCredentials: true, // Si necesitas enviar cookies entre dominios
              });
            console.log('Respuesta recibida:', response);  // Mostrar la respuesta completa
    
            // Verifica que la respuesta tenga el ID esperado
            if (response.data && response.data.id) {
                const id  = response.data.id;
                console.log('ID recibido:', id);  // Mostrar el ID recibido
                return id;  // Retornar el ID si existe
            } else {
                throw new Error('La respuesta de la API no contiene un ID válido.');
            }
    
        } catch (error) {
            console.error('Error al crear la preferencia de compra:', error);
        }
}; */

//3
/* const handleBuy = async () => {

    try {
        const id = await createPreference();
        if (id) {
          setpreferenceId(id);  // Guarda el ID de la preferencia en tu estado
        }
      } catch (error) {
        console.error("Error al realizar la compra:", error);
      }
  };
 */
    const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("toytek-carrito")) || []);
    const handleClick = (e) => {

    eliminarDelCarrito(e);
    setProductosEnCarrito(JSON.parse(localStorage.getItem("toytek-carrito")));
  };

/*   const clickingPago2 = () => {
    Swal.fire({
        title: `¿Realizar Pago por $ ${nf.format(TvalorProductoArt)}.-, (link externo de Mercadopago)?`,
        text: "TRANSFERENCIAS hábleme al WHATSAPP",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PAGAR"
    }).then((result) => {
        if (result.isConfirmed) {
            const url = 'https://link.mercadopago.cl/toytek';
            var win = window.open(url, '_blank');
            win.focus();
        }
    });
} */

const cargarProductos = () => {
    const productos = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
    setProductosEnCarrito(productos);
};

useEffect(() => {
    cargarProductos();
 }, []); // Solo al montar

  
 let TvalorProductoArt = 0, TvalorProductoArtFormat = 0;
  return (<div className="container-fluid" style={{paddingTop: '140px'}}>
<div className="row px-xl-5">
    <div className="col-lg-8 table-responsive mb-5">
        <table className="table table-light table-borderless table-hover text-center mb-0">
            <thead className="thead-dark">
                <tr>
                    <th>Productos</th>
                    <th>Precio</th>
                    <th>Eliminar</th>
                </tr>
            </thead>
            <tbody className="align-middle">
            {   productosEnCarrito.length > 0 &&
                productosEnCarrito.map((producto, i) => {
                    
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          let foto1 = '';
          TvalorProductoArt = productosEnCarrito.reduce((acc, producto) => acc + producto.valor, 0);
          TvalorProductoArtFormat = `$ ${nf.format(TvalorProductoArt)}`;
           if(producto.categorias === 15 || producto.categorias === 16){
            foto1 = `${producto.foto1}`;
           }else{
            foto1 = `${producto.foto1}_i.JPG`;
           }
            
           

           
          return (

            <tr  key={`${producto.id}`} >
                    <td className="align-middle" style={{textAlign: 'left'}} id="title"><img src={`${foto1}`} alt="" style={{width: '50px'}} />&nbsp;&nbsp;&nbsp;{producto.descripcion}</td>
                    <td className="align-middle" id="price">{valorProductoArt}</td>
                    <td className="align-middle"><button className="btn btn-sm btn-danger carrito-producto-eliminar" id={`${producto.id}`} onClick={handleClick}><XLg size={18} color='white'></XLg></button></td>
                </tr>
          )
        })
      }  
            </tbody>
        </table>
    </div>
    <div className="col-lg-4">
        <form className="mb-30" action="">
            <div className="input-group">
                <input type="text" className="form-control border-0 p-4" placeholder="Código" />
                <div className="input-group-append">
                    <button className="btn btn-primary" disabled>Aplicar Código</button>
                </div>
            </div>
        </form>
        <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3" style={{color:'white'}}>Resumen del Carrito</span></h5>
        <div className="bg-light p-30 mb-5">
            <div className="border-bottom pb-2">
                <div className="d-flex justify-content-between mb-3">
                    <h6>Sub Total</h6>
                    <h6>{TvalorProductoArtFormat}</h6>
                </div>
                <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Despacho <span style={{color:'blue',fontWeight: 'lighter'}}>STARKEN</span><span style={{color:'blue',fontWeight: 'bold'}}> VALPARAÍSO</span></h6>
                    <h6 className="font-weight-medium">por PAGAR</h6>
                </div>
            </div>
            <div className="pt-2">
                <div className="d-flex justify-content-between mt-2">
                    <h5>Total</h5>
                    <h5>{TvalorProductoArtFormat}</h5>
                </div>
                <h6 style={{textAlign: 'right'}}>Impuesto incluido</h6>
                <Link to="/checkout"><button className="btn btn-block btn-primary font-weight-bold my-3 py-3" disabled={productosEnCarrito.length === 0}>Confirmar Compra</button></Link>
                {/* || preferenceId */}
               {/* {preferenceId &&
                <Wallet initialization={{ preferenceId: `${preferenceId}`}} customization={{ texts:{ valueProp: 'smart_option'}}} />
               } */}
            </div>
        </div>
    </div>
</div>
</div>);
}

export default App;