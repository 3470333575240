import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Checkout from '../components/checkout.js';
import Breadcrumb from '../components/breadcrumb.js';
function App() {

return (
  <>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Breadcrumb categoria="Carro de Compras" url="/cart" descripcion="Verificación"/><Checkout />
    <Wsp></Wsp></>
);
}

export default App;