
import { Link } from 'react-router-dom';
import '../css/style.css';
import CarroNumero from './carroNumero.js'
import {Justify, ArrowDownSquare} from 'react-bootstrap-icons';
import * as Icon from 'react-bootstrap-icons';

function NavCarrito() {

    return (<div className="container-fluid bg-dark mb-30" style={{ position: 'fixed', marginTop: '100px', zIndex: '1000' }}>
        <div className="row px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
                <Link className="btn d-flex align-items-center justify-content-between bg-primary w-100 collapsed" data-toggle="collapse" data-target="#navbar-vertical" style={{ height: '65px', padding: '0 30px' }}>
                    <h6 className="text-dark m-0"><Justify size={28}></Justify>Categorías</h6>
                    <ArrowDownSquare size={28} color='white'></ArrowDownSquare>
                </Link>
                <nav className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light" id="navbar-vertical" style={{ width: 'calc(100% - 30px)', zIndex: '999' }}>
                    <div className="navbar-nav w-100">
                        <Link to="/collections/figuras-de-accion" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Acción</Link>
                        <Link to="/collections/figuras-articuladas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Articuladas</Link>
                        <Link to="/collections/autitos" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Autitos</Link>
                        <Link to="/collections/figuras-monocromaticas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Monocromáticas</Link>
                        <Link to="/collections/papeleria" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Papelería</Link>
                        <Link to="/collections/peliculas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Películas</Link>
                        <Link to="/collections/figuras-plasticas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Plásticas</Link>
                        <Link to="/collections/poleras" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Poleras</Link>
                        <Link to="/collections/promocionales" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Promocionales</Link>
                        <Link to="/collections/figuras-de-pvc" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >PVC</Link>
                        <Link to="/collections/rompecabezas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Rompecabezas</Link>
                        <Link to="/collections/tazas" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Tazas</Link>
                        <Link to="/collections/all" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >TODOS</Link>
                        <Link to="/collections/videojuegos" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Videojuegos</Link>
                        <Link to="/collections/variados" className="text-dark nav-item nav-link" style={{backgroundColor: '#0062f3', color: 'white'}} >Variados</Link>
                    </div>
                </nav>
            </div>
            <div className="col-lg-9">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
                    <div to="" className="text-decoration-none d-block d-lg-none">
                        <div className="px-2" style={{ paddingBottom: '5px' }}><img id="logo-img" className="img-fluid" src={require(`../assets/logo/toytek.png`)} alt="" style={{ width: '150px' }} /></div>
                        <div className="px-2 ml-n1">&nbsp;&nbsp;<Link to="https://www.youtube.com/@toytek_cl" target="_blank"><Icon.Youtube color='white' size={20}></Icon.Youtube></Link>&nbsp;&nbsp;<Link to="https://www.instagram.com/toytek.cl/" target="_blank"><Icon.Instagram color='white' size={16}></Icon.Instagram></Link>&nbsp;&nbsp;<Link to="https://www.facebook.com/toytek.cl/" target="_blank"><Icon.Facebook color='white' size={16}></Icon.Facebook></Link>&nbsp;&nbsp;</div>
                    </div>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav mr-auto py-0">
                            <Link to="/" className="nav-item nav-link">Inicio</Link>
                            <div className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="true">Categorías <ArrowDownSquare size={20}></ArrowDownSquare></Link>
                                <div className="dropdown-menu bg-primary rounded-0 border-0 m-0 ">
                                    <Link to="/collections/figuras-de-accion" className="text-dark dropdown-item">Acción</Link>
                                    <Link to="/collections/figuras-articuladas" className="text-dark dropdown-item">Articuladas</Link>
                                    <Link to="/collections/autitos" className="text-dark dropdown-item">Autitos</Link>
                                    <Link to="/collections/figuras-monocromaticas" className="text-dark dropdown-item">Monocromáticas</Link>
                                    <Link to="/collections/papeleria" className="text-dark dropdown-item">Papelería</Link>
                                    <Link to="/collections/peliculas" className="text-dark dropdown-item">Películas</Link>
                                    <Link to="/collections/figuras-plasticas" className="text-dark dropdown-item">Plásticas</Link>
                                    <Link to="/collections/poleras" className="text-dark dropdown-item">Poleras</Link>
                                    <Link to="/collections/promocionales" className="text-dark dropdown-item">Promocionales</Link>
                                    <Link to="/collections/figuras-de-pvc" className="text-dark dropdown-item">PVC</Link>
                                    <Link to="/collections/rompecabezas" className="text-dark dropdown-item">Rompecabezas</Link>
                                    <Link to="/collections/tazas" className="text-dark dropdown-item">Tazas</Link>
                                    <Link to="/collections/all" className="text-dark dropdown-item">TODOS</Link>
                                    <Link to="/collections/videojuegos" className="text-dark dropdown-item">Videojuegos</Link>
                                    <Link to="/collections/variados" className="text-dark dropdown-item">Variados</Link>
                                </div>
                            </div>
                            {/*<Link to="/account/register" className="nav-item nav-link">Registro</Link>
                            <Link to="/account/login" className="nav-item nav-link">Login</Link>*/}
                            <Link to="/pages/contact" className="nav-item nav-link">Contacto</Link> 
                            <Link to="/pages/pagos-y-despachos" className="nav-item nav-link">Pagos y Despachos</Link>
                        </div>

                            <div className="navbar-nav ml-auto py-0 d-none d-lg-block">
                            <CarroNumero /></div> 
                      
                    </div>
                </nav>
            </div>
        </div>
    </div>
    );
}

export default NavCarrito;
