import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Elementos from '../components/Productos.js';
function App() {

return (
  <>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Elementos categoriaId='0' categoriaNombre={'Todos los productos'} pTop="pt-55"/>
    <Wsp></Wsp></>
);
}

export default App;